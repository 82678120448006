import React, { Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { AuthorisedLayout } from "./components/AuthorisedLayout";
import { LocalStorage } from "./helper/appsession";
import LazyLoad from "./LazyLoad";
import { UserType } from "./helper/fixcode";
import { Loader } from "./components/Shared/PageLoader";

const Wrapper = LazyLoad("@googlemaps/react-wrapper");
const DashboardComponent = LazyLoad(
  "./components/Dashboard/DashboardComponent"
);
const LoginPage = LazyLoad("./components/LoginPage");
const LeadsComponent = LazyLoad("./components/Leads/LeadsComponent");
const LeadMasterComponent = LazyLoad("./components/Leads/LeadMasterComponent");
const RefundComponent = LazyLoad("./components/Refunds/RefundsComponent");
const DashboardLeadsComponent = LazyLoad(
  "./components/Leads/DashboardLeadsComponent"
);
const ComingsoonComponent = LazyLoad(
  "./components/Dashboard/ComingsoonComponent"
);
const ViewPermissionComponent = LazyLoad(
  "./components/Dashboard/ViewPermissionComponent"
);
const AddLeadsComponents = LazyLoad("./components/Leads/AddLeadsComponents");
const EditLeadsComponents = LazyLoad("./components/Leads/EditLeadsComponents");
const VehicleComponent = LazyLoad("./components/Master/VehicleComponent");
const CreateVehicleComponent = LazyLoad(
  "./components/Master/CreateVehicleComponent"
);
const DriverOnboardingComponent = LazyLoad(
  "./components/DriverOnboarding/DriverOnboardingComponent"
);
const SaveDriverOnboardingComponent = LazyLoad(
  "./components/DriverOnboarding/SaveDriverOnboardingComponent"
);
const SaveDriverPartnerInfoComponent = LazyLoad(
  "./components/DriverOnboarding/SaveDriverPartnerInfoComponent"
);
const SaveAadharCardDetailsComponent = LazyLoad(
  "./components/DriverOnboarding/SaveAadharCardDetailsComponent"
);
const SavePanCardDetailsComponent = LazyLoad(
  "./components/DriverOnboarding/SavePanCardDetailsComponent"
);
const SaveDrivingLicenseDetailsComponent = LazyLoad(
  "./components/DriverOnboarding/SaveDrivingLicenseDetailsComponent"
);
const SaveDriverProfilePhotoComponent = LazyLoad(
  "./components/DriverOnboarding/SaveDriverProfilePhotoComponent"
);
const SavePoliceClearanceCertificateComponent = LazyLoad(
  "./components/DriverOnboarding/SavePoliceClearanceCertificateComponent"
);
const SaveCourtRecordStatusComponent = LazyLoad(
  "./components/DriverOnboarding/SaveCourtRecordStatusComponent"
);
const SaveEmergencyContactComponent = LazyLoad(
  "./components/DriverOnboarding/SaveEmergencyContactComponent"
);
const SaveGuarantorDetailsComponent = LazyLoad(
  "./components/DriverOnboarding/SaveGuarantorDetailsComponent"
);
const SaveAddressDetailsComponent = LazyLoad(
  "./components/DriverOnboarding/SaveAddressDetailsComponent"
);
const SaveBankDetailsComponent = LazyLoad(
  "./components/DriverOnboarding/SaveBankDetailsComponent"
);
const SchemeComponent = LazyLoad("./components/Scheme/SchemeComponent");

const SchemeMasterComponent = LazyLoad(
  "./components/Scheme/SchemeMasterComponent"
);

const FieldExecutivesComponent = LazyLoad(
  "./components/User/FieldExecutivesComponent"
);
const IncentivesComponent = LazyLoad(
  "./components/Incentives/IncentivesComponent"
);
const UserComponent = LazyLoad("./components/User/UserComponent");
const RoleComponent = LazyLoad("./components/Role/RoleComponent");
const UserTypeComponent = LazyLoad("./components/User/UserTypeComponent");
const FeatureComponent = LazyLoad("./components/Feature/FeatureComponent");
const DriverDocumentQCComponent = LazyLoad(
  "./components/DriverDocumentQC/DriverDocumentQCComponent"
);
const ViewDriverDocumentQCComponent = LazyLoad(
  "./components/DriverDocumentQC/ViewDriverDocumentQCComponent"
);
const ViewLeadsComponent = LazyLoad("./components/Leads/ViewLeadsComponent");
const RazorpayPaymentComponent = LazyLoad(
  "./components/PaymentGateway/RazorpayPaymentComponent"
);
const BankAcountConfirmationComponent = LazyLoad(
  "./components/Callback/BankAcountConfirmationComponent"
);
const BankAccountCancelComponent = LazyLoad(
  "./components/Callback/BankAccountCancelComponent"
);
const CarOnboardingComponent = LazyLoad(
  "./components/CarOnboarding/CarOnboardingComponent"
);

const TicketComponent = LazyLoad("./components/Ticket/TicketComponent");

const ViewDriverOnBoardingComponent = LazyLoad(
  "./components/DriverOnboarding/ViewDriverOnBoardingComponent"
);

const VendorChampionsComponent = LazyLoad(
  "./components/Vendor/VendorChampionsComponent"
);

const AddVendorComponent = LazyLoad("./components/Vendor/AddVendorComponent");
const VendorsComponent = LazyLoad("./components/Vendor/VendorsComponent");
const DriverPartnerMasterComponent = LazyLoad(
  "./components/DriverPartner/DriverPartnerMasterComponent"
);

const TrainingScheduleComponent = LazyLoad(
  "./components/Training/TrainingScheduleComponent"
);

const IncentiveSchemeComponent = LazyLoad(
  "./components/Incentives/IncentiveSchemeComponent"
);

const TargetMappingComponent = LazyLoad(
  "./components/ChangeRequest/TargetMappingComponent"
);

const MarkCarforRecoveryComponent = LazyLoad(
  "./components/ChangeRequest/MarkCarforRecoveryComponent"
);
const CarImmobilizationComponent = LazyLoad(
  "./components/ChangeRequest/CarImmobilizationComponent"
);

const WaiverComponent = LazyLoad("./components/ChangeRequest/WaiverComponent");
const PenaltyComponent = LazyLoad(
  "./components/ChangeRequest/PenaltyComponent"
);

const DriverParkComponent = LazyLoad(
  "./components/ChangeRequest/DriverParkComponent"
);

const DriverTerminateComponent = LazyLoad(
  "./components/ChangeRequest/DriverTerminateComponent"
);

const DriverBlackListComponent = LazyLoad(
  "./components/ChangeRequest/DriverBlackListComponent"
);

const SchemeChangeRequestComponent = LazyLoad(
  "./components/ChangeRequest/SchemeChangeRequestComponent"
);
const VehicleReleaseComponent = LazyLoad(
  "./components/ChangeRequest/VehicleReleaseComponent"
);

const TrainingSlotsComponent = LazyLoad(
  "./components/Training/TrainingSlotsComponent"
);

const AgentCurrentSettlementComponent = LazyLoad(
  "./components/AgentSettlement/AgentCurrentSettlementComponent"
);
const AgentSettlementComponent = LazyLoad(
  "./components/AgentSettlement/AgentSettlementComponent"
);
const DashboardSourcingManagerComponent = LazyLoad(
  "./components/Dashboard/DashboardSourcingManagerComponent"
);
const OperationManagerPerformanceDashbaordComponent = LazyLoad(
  "./components/Dashboard/OperationManagerPerformanceDashbaordComponent"
);

const OperationManagerDashboardComponent = LazyLoad(
  "./components/Dashboard/OperationManagerDashboardComponent"
);

const DriverPartnerDashboardComponent = LazyLoad(
  "./components/Dashboard/DriverPartnerDashboardComponent"
);

const FieldEmployeeComponent = LazyLoad(
  "./components/Dashboard/FieldEmployeeComponent"
);

const RandMDashboardComponent = LazyLoad(
  "./components/Dashboard/RandMDashboardComponent"
);

const DriverPartnerSettlementComponent = LazyLoad(
  "./components/DriverPartner/DriverPartnerSettlementComponent"
);

const DriverManagerSettlementComponent = LazyLoad(
  "./components/DriverPartner/DriverManagerSettlementComponent"
);

const OperatorMasterComponent = LazyLoad(
  "./components/Operator/OperatorMasterComponent"
);

const AddSchemeComponents = LazyLoad("./components/Scheme/AddSchemeComponents");
const SchemeAddonComponents = LazyLoad(
  "./components/Scheme/SchemeAddonComponents"
);
const SchemeDeductionComponents = LazyLoad(
  "./components/Scheme/SchemeDeductionComponents"
);

const OperationsPartnerIncentiveComponent = LazyLoad(
  "./components/Incentives/OperationsPartnerIncentiveComponent"
);

const DriverPartnerParkComponent = LazyLoad(
  "./components/DriverStatusChange/DriverPartnerParkComponent"
);
const DriverPartnerTerminateComponent = LazyLoad(
  "./components/DriverStatusChange/DriverPartnerTerminateComponent"
);

const DriverPartnerBlocklistComponent = LazyLoad(
  "./components/DriverStatusChange/DriverPartnerBlocklistComponent"
);

const WarRoomComponent = LazyLoad("./components/Tracking/WarRoomComponent");
const VehicleQCComponent = LazyLoad(
  "./components/VehicleQC/VehicleQCComponent"
);
const ViewVehicleQCComponent = LazyLoad(
  "./components/VehicleQC/ViewVehicleQCComponent"
);
const ViewVehicleInspectionQCComponent = LazyLoad(
  "./components/VehicleQC/ViewVehicleInspectionQCComponent"
);
const ViewInventoryVehicleQCComponent = LazyLoad(
  "./components/VehicleQC/ViewInventoryVehicleQCComponent"
);
const VehicleInspectionQCComponent = LazyLoad(
  "./components/VehicleQC/VehicleInspectionQCComponent"
);

const CompetitorSchemeComponent = LazyLoad(
  "./components/Scheme/CompetitorSchemeComponent"
);
const Champion = LazyLoad("./components/User/ChampionComponent");
const ImportLeadsComponent = LazyLoad(
  "./components/Leads/ImportLeadsComponent"
);

const VehicleChangeComponent = LazyLoad(
  "./components/Vehicle/VehicleChangeComponent"
);
const VehicleMasterComponent = LazyLoad(
  "./components/Vehicle/VehicleMasterComponent"
);
const VehicleAlertsComponent = LazyLoad(
  "./components/Tracking/VehicleAlertsComponent"
);

const RateCardComponent = LazyLoad("./components/Services/RateCardComponent");
const EstimateComponent = LazyLoad("./components/Services/EstimateComponent");
const WorkApprovalComponent = LazyLoad(
  "./components/Services/WorkApprovalComponent"
);

const VehicleProfitLossComponent = LazyLoad(
  "./components/Master/VehicleProfitLossComponent"
);
const StampPaperInventoryComponent = LazyLoad(
  "./components/Legal/StampPaperInventoryComponent"
);
const StampPaperSummeryComponent = LazyLoad(
  "./components/Legal/StampPaperSummeryComponent"
);
const LeadCostComponent = LazyLoad(
  "./components/MarketingExpenses/LeadCostComponent"
);
const GateRegisterComponent = LazyLoad(
  "./components/GateRegister/GateRegisterComponent"
);
const BranchAreaComponent = LazyLoad("./components/Master/BranchAreaComponent");
const TrainingLocation = LazyLoad("./components/Training/TrainingLocation");
const VehicleAllocationComponent = LazyLoad(
  "./components/VehicleAllocation/VehicleAllocationComponent"
);
const LeadInfoComponent = LazyLoad("./components/Legal/LeadInfoComponent");

const VehicleInfoComponent = LazyLoad(
  "./components/Legal/VehicleInfoComponent"
);
const StatusComponent = LazyLoad("./components/Master/StatusComponent");
const CategoryComponent = LazyLoad("./components/Master/CategoryComponent");
const SchemeInfoComponent = LazyLoad("./components/Legal/SchemeInfoComponent");
const LeadAgreementComponent = LazyLoad(
  "./components/Legal/LeadAgreementComponent"
);
const AgreementContractComponent = LazyLoad(
  "./components/Legal/AgreementContractComponent"
);
const AgreementVerificationComponent = LazyLoad(
  "./components/Legal/AgreementVerificationComponent"
);
const SchemeDepositComponent = LazyLoad(
  "./components/Scheme/SchemeDepositComponent"
);
const SchemeAgreementComponent = LazyLoad(
  "./components/Scheme/SchemeAgreementComponent"
);
const TrainingDashboardComponent = LazyLoad(
  "./components/Training/TrainingDashboardComponent"
);
const ServiceCenterComponent = LazyLoad(
  "./components/Master/ServiceCenterComponent"
);
const AgentAttendanceLogComponent = LazyLoad(
  "./components/Master/AgentAttendanceLogComponent"
);

const PaymentTransactionComponent = LazyLoad(
  "./components/PaymentTransaction/PaymentTransactionComponent"
);

const RazorpaySettlementReconsComponent = LazyLoad(
  "./components/RazorpaySettlementRecons/RazorpaySettlementReconsComponent"
);
const GeneratePayoutComponent = LazyLoad(
  "./components/Payout/GeneratePayoutComponent"
);
const PostPayoutComponent = LazyLoad("./components/Payout/PostPayoutComponent");
const PayoutListComponent = LazyLoad("./components/Payout/PayoutListComponent");
const PayoutViewComponent = LazyLoad("./components/Payout/PayoutViewComponent");
const ReportConfigurationComponent = LazyLoad(
  "./components/Configuration/ReportConfigurationComponent"
);
const UberOrgnizationsComponent = LazyLoad(
  "./components/Uber/UberOrgnizationsComponent"
);

const GatePassComponent = LazyLoad("./components/Legal/GatePassComponent");
const ItemCategoryComponent = LazyLoad(
  "./components/JobEstimate/ItemCategoryComponent"
);
const ItemsComponent = LazyLoad("./components/JobEstimate/ItemsComponent");
const ItemTypeComponent = LazyLoad(
  "./components/JobEstimate/ItemTypeComponent"
);
const SystemPayoutAddon = LazyLoad("./components/Payout/SystemPayoutAddon");
const SystemPayoutDeduction = LazyLoad(
  "./components/Payout/SystemPayoutDeduction"
);
const SystemPayoutCredit = LazyLoad("./components/Payout/SystemPayoutCredit");
const ImportDriverDetailsComponent = LazyLoad(
  "./components/DriverDetails/ImportDriverDetailsComponent"
);
const ImportVehiclesDetailsComponent = LazyLoad(
  "./components/ImportData/ImportVehicleDetailsComponent"
);
const CustomerBranchComponent = LazyLoad(
  "./components/Master/CustomerBranchComponent"
);
const PayoutProcessedDataComponent = LazyLoad(
  "./components/Payout/PayoutProcessedDataComponent"
);

const AmeyoToolbarComponent = LazyLoad(
  "./components/Ameyo/AmeyoToolbarComponent"
);
const AdjustmentComponent = LazyLoad(
  "./components/ChangeRequest/AdjustmentComponent"
);

const ParentChildUserMappingComponent = LazyLoad(
  "./components/Master/ParentChildUserMappingComponent"
);
const ServiceNotificationBatch = LazyLoad(
  "./components/Notification/ServiceNotificationBatch"
);
const TeleAgentComponent = LazyLoad("./components/User/TeleAgentComponent");
const PayoutCycleComponent = LazyLoad(
  "./components/Payout/PayoutCycleComponent"
);
const PaymentGatewayComponent = LazyLoad(
  "./components/PaymentGateway/PaymentGatewayComponent"
);
const UberReportDriverPerformanceComponent = LazyLoad(
  "./components/Payout/UberReportDriverPerformanceComponent"
);
const UberReportDriverStatusComponent = LazyLoad(
  "./components/Payout/UberReportDriverStatusComponent"
);
const PayLaterComponent = LazyLoad(
  "./components/PartialPayment/PayLaterComponent"
);
function App() {
  const isAuthorized = LocalStorage.IsAuthorized;
  return (
    <>
      {isAuthorized === true ? (
        <AuthorisedLayout>
          <Routes>
            <Route path="/" element={<AuthorizedRouteWrapper />}>
              <Route path="*" element={<RanderDashboard />} />
              <Route path="/" element={<RanderDashboard />} />
              <Route path="/home" element={<RanderDashboard />} />
              {/* <Route path="*" element={<DashboardComponent />} />
              <Route path="/" element={<DashboardComponent />} /> 
              <Route path="/home" element={<RanderDashboard />} />
              */}
              <Route
                path="/operationmanagerdashboard"
                element={<OperationManagerDashboardComponent />}
              />
              <Route
                path="/randmdashboard"
                element={<RandMDashboardComponent />}
              />
              <Route
                path="/operationmanagerperformancedashbaord"
                element={<OperationManagerPerformanceDashbaordComponent />}
              />
              <Route path="/leads" element={<LeadMasterComponent />} />
              <Route path="/leads-import" element={<ImportLeadsComponent />} />

              <Route path="/refund" element={<RefundComponent />} />
              <Route
                path="/dashboardleads"
                element={<DashboardLeadsComponent />}
              />
              <Route path="/addleads" element={<AddLeadsComponents />} />
              <Route path="/editleads" element={<EditLeadsComponents />} />
              <Route path="/vehicles" element={<VehicleComponent />} />
              <Route
                path="/createvehicle"
                element={<CreateVehicleComponent />}
              />
              <Route
                path="/driveronboarding"
                element={<DriverOnboardingComponent />}
              />
              <Route
                path="/savedriveronboarding"
                element={<SaveDriverOnboardingComponent />}
              />
              <Route
                path="/savedriverparnterinfo"
                element={<SaveDriverPartnerInfoComponent />}
              />
              <Route
                path="/saveaadhardetails"
                element={<SaveAadharCardDetailsComponent />}
              />
              <Route
                path="/savepancarddetails"
                element={<SavePanCardDetailsComponent />}
              />
              <Route
                path="/savedrivinglicensedetails"
                element={<SaveDrivingLicenseDetailsComponent />}
              />
              <Route
                path="/savedriverprofilephoto"
                element={<SaveDriverProfilePhotoComponent />}
              />
              <Route
                path="/savepolicecertificate"
                element={<SavePoliceClearanceCertificateComponent />}
              />
              <Route
                path="/savecourtrecordstatus"
                element={<SaveCourtRecordStatusComponent />}
              />
              <Route
                path="/saveemergencycontact"
                element={<SaveEmergencyContactComponent />}
              />
              <Route
                path="/saveguarantordetails"
                element={<SaveGuarantorDetailsComponent />}
              />
              <Route
                path="/saveaddressdetails"
                element={<SaveAddressDetailsComponent />}
              />
              <Route
                path="/savebankdetails"
                element={<SaveBankDetailsComponent />}
              />
              <Route
                path="/field-employee"
                element={<FieldEmployeeComponent />}
              />
              <Route path="/user" element={<UserComponent />} />
              <Route path="/role" element={<RoleComponent />} />
              <Route path="/usertype" element={<UserTypeComponent />} />
              <Route path="/feature" element={<FeatureComponent />} />
              <Route
                path="/document-qc"
                element={<DriverDocumentQCComponent />}
              />
              <Route
                path="/view-document-qc"
                element={<ViewDriverDocumentQCComponent />}
              />
              <Route path="/viewleads" element={<ViewLeadsComponent />} />
              <Route
                path="/viewdriveronboarding"
                element={<ViewDriverOnBoardingComponent />}
              />
              <Route path="/schememaster" element={<SchemeMasterComponent />} />
              <Route
                path="/addschememaster"
                element={<AddSchemeComponents />}
              />
              <Route path="/schemeaddon" element={<SchemeAddonComponents />} />
              <Route
                path="/schemededuction"
                element={<SchemeDeductionComponents />}
              />
              <Route
                path="/fieldexecutive"
                element={<FieldExecutivesComponent />}
              />
              <Route path="/incentive" element={<IncentivesComponent />} />
              <Route path="/ticket" element={<TicketComponent />} />
              <Route
                path="/car-onboarding"
                element={<CarOnboardingComponent />}
              />
              <Route
                path="/vendorchampions"
                element={<VendorChampionsComponent />}
              />
              <Route path="/vendor" element={<VendorsComponent />} />
              <Route
                path="/driverpartnermaster"
                element={<DriverPartnerMasterComponent />}
              />
              <Route path="/addvendor" element={<AddVendorComponent />} />
              <Route
                path="/trainingschedule"
                element={<TrainingScheduleComponent />}
              />
              <Route
                path="/trainingslots"
                element={<TrainingSlotsComponent />}
              />
              <Route
                path="/incentive-scheme"
                element={<IncentiveSchemeComponent />}
              />
              <Route
                path="/targetmapping"
                element={<TargetMappingComponent />}
              />
              <Route
                path="/markcarforrecovery"
                element={<MarkCarforRecoveryComponent />}
              />
              <Route
                path="/carimmobilization"
                element={<CarImmobilizationComponent />}
              />
              <Route path="/waiver" element={<WaiverComponent />} />
              <Route path="/penalty" element={<PenaltyComponent />} />
              <Route path="/driverpark" element={<DriverParkComponent />} />
              <Route
                path="/driverterminate"
                element={<DriverTerminateComponent />}
              />
              <Route
                path="/driverblacklist"
                element={<DriverBlackListComponent />}
              />
              <Route
                path="/schemechangerequest"
                element={<SchemeChangeRequestComponent />}
              />
              <Route
                path="/vehiclerelease"
                element={<VehicleReleaseComponent />}
              />
              <Route
                path="/agent-current-perfomance"
                element={<AgentCurrentSettlementComponent />}
              />
              <Route
                path="/agent-perfomance"
                element={<AgentSettlementComponent />}
              />
              <Route
                path="/driver-settlement"
                element={<DriverPartnerSettlementComponent />}
              />
              <Route
                path="/drivermanagersettlement"
                element={<DriverManagerSettlementComponent />}
              />
              <Route
                path="/operatormaster"
                element={<OperatorMasterComponent />}
              />
              <Route path="/warroom" element={<WarRoomComponent />} />
              <Route path="/champion" element={<Champion />} />
              <Route
                path="/operationspartnerincentive"
                element={<OperationsPartnerIncentiveComponent />}
              />
              <Route
                path="/driverpartnerpark"
                element={<DriverPartnerParkComponent />}
              />
              <Route
                path="/driverpartnerterminate"
                element={<DriverPartnerTerminateComponent />}
              />
              <Route
                path="/driverpartnerblocklist"
                element={<DriverPartnerBlocklistComponent />}
              />
              <Route path="/vehicleqc" element={<VehicleQCComponent />} />
              <Route
                path="/vehicleinspectionqc"
                element={<VehicleInspectionQCComponent />}
              />
              <Route
                path="/viewvehicleqc"
                element={<ViewVehicleQCComponent />}
              />
              <Route
                path="/viewvehicleinspectionqc"
                element={<ViewVehicleInspectionQCComponent />}
              />
              <Route
                path="/viewvehicleinventoryqc"
                element={<ViewInventoryVehicleQCComponent />}
              />
              <Route
                path="/competitor-scheme"
                element={<CompetitorSchemeComponent />}
              />
              <Route
                path="/vehiclechange"
                element={<VehicleChangeComponent />}
              />
              <Route
                path="/vehiclemaster"
                element={<VehicleMasterComponent />}
              />
              <Route
                path="/driverpartnerdashboard"
                element={<DriverPartnerDashboardComponent />}
              />
              <Route
                path="/vehiclealerts"
                element={<VehicleAlertsComponent />}
              />
              <Route path="/rate-card" element={<RateCardComponent />} />
              <Route path="/estimate" element={<EstimateComponent />} />
              <Route path="/workapproval" element={<WorkApprovalComponent />} />
              <Route
                path="/vehicleprofitloss"
                element={<VehicleProfitLossComponent />}
              />
              <Route
                path="/stamppaperinventory"
                element={<StampPaperInventoryComponent />}
              />
              <Route
                path="/stamppapersummery"
                element={<StampPaperSummeryComponent />}
              />
              <Route path="/leadcost" element={<LeadCostComponent />} />
              <Route path="/gateregister" element={<GateRegisterComponent />} />
              <Route path="/branch-area" element={<BranchAreaComponent />} />
              <Route path="/training-location" element={<TrainingLocation />} />
              <Route
                path="/vehicleallocation"
                element={<VehicleAllocationComponent />}
              />
              <Route path="/leadinfo" element={<LeadInfoComponent />} />
              <Route path="/vehicleinfo" element={<VehicleInfoComponent />} />
              <Route path="/schemeinfo" element={<SchemeInfoComponent />} />
              <Route path="/status" element={<StatusComponent />} />
              <Route path="/category" element={<CategoryComponent />} />
              <Route
                path="/trainingdashboard"
                element={<TrainingDashboardComponent />}
              />
              <Route
                path="/leadagreement"
                element={<LeadAgreementComponent />}
              />
              <Route
                path="/agreementcontract"
                element={<AgreementContractComponent />}
              />
              <Route
                path="/schemedeposit"
                element={<SchemeDepositComponent />}
              />
              <Route
                path="/schemeagreement"
                element={<SchemeAgreementComponent />}
              />
              <Route
                path="/service-center"
                element={<ServiceCenterComponent />}
              />
              <Route
                path="/agent-attendance"
                element={<AgentAttendanceLogComponent />}
              />
              <Route
                path="/payment-transaction"
                element={<PaymentTransactionComponent />}
              />
              <Route
                path="/settlement-recons"
                element={<RazorpaySettlementReconsComponent />}
              />
              <Route
                path="/payout-generate"
                element={<GeneratePayoutComponent />}
              />
              <Route path="/payout-post" element={<PostPayoutComponent />} />
              <Route path="/payout" element={<PayoutListComponent />} />
              <Route path="/payout-view" element={<PayoutViewComponent />} />
              <Route path="/gatepass" element={<GatePassComponent />} />
              <Route
                path="/item-category"
                element={<ItemCategoryComponent />}
              />
              <Route path="/items" element={<ItemsComponent />} />
              <Route path="/item-type" element={<ItemTypeComponent />} />
              <Route path="/payout-sys-addon" element={<SystemPayoutAddon />} />
              <Route
                path="/payout-data"
                element={<PayoutProcessedDataComponent />}
              />
              <Route
                path="/payout-sys-deduction"
                element={<SystemPayoutDeduction />}
              />
              <Route
                path="/payout-sys-credit"
                element={<SystemPayoutCredit />}
              />
              <Route
                path="/agent-attendance"
                element={<AgentAttendanceLogComponent />}
              />
              <Route
                path="/payment-transaction"
                element={<PaymentTransactionComponent />}
              />
              <Route
                path="/settlement-recons"
                element={<RazorpaySettlementReconsComponent />}
              />
              <Route
                path="/reportconfiguration"
                element={<ReportConfigurationComponent />}
              />
              <Route
                path="/orgnizations"
                element={<UberOrgnizationsComponent />}
              />
              <Route
                path="/import-drivers"
                element={<ImportDriverDetailsComponent />}
              />
              <Route
                path="/import-vehicles"
                element={<ImportVehiclesDetailsComponent />}
              />
              <Route
                path="/customer-branch"
                element={<CustomerBranchComponent />}
              />
              <Route path="/ameyotoolbar" element={<AmeyoToolbarComponent />} />
              <Route path="/welcome" element={<ComingsoonComponent />} />
              <Route path="/permission" element={<ViewPermissionComponent />} />
              <Route path="/adjustment" element={<AdjustmentComponent />} />
              <Route
                path="/user-mapping"
                element={<ParentChildUserMappingComponent />}
              />
              <Route
                path="/service-notification"
                element={<ServiceNotificationBatch />}
              />
              <Route path="/tele-agent" element={<TeleAgentComponent />} />
              <Route path="/payout-cycle" element={<PayoutCycleComponent />} />
              <Route
                path="/payment-gateway"
                element={<PaymentGatewayComponent />}
              />
              <Route
                path="/driver-performance"
                element={<UberReportDriverPerformanceComponent />}
              />
              <Route
                path="/driver-status"
                element={<UberReportDriverStatusComponent />}
              />
              <Route
                path="/paylater"
                element={<PayLaterComponent />}
              />
            </Route>
          </Routes>
        </AuthorisedLayout>
      ) : (
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/razorpay-payment"
              element={<RazorpayPaymentComponent />}
            />
            <Route
              path="/agreement-verification"
              element={<AgreementVerificationComponent />}
            />
            <Route
              path="/bank-account-confirm"
              element={<BankAcountConfirmationComponent />}
            />
            <Route
              path="/bank-account-cancel"
              element={<BankAccountCancelComponent />}
            />
          </Routes>
        </Suspense>
      )}
    </>
  );
}
export default App;

function AuthorizedRouteWrapper() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </>
  );
}
function RanderDashboard() {
  let dashboard = <DashboardComponent />;
  switch (LocalStorage.UserTypeId) {
    case UserType.SourcingManagerAdmin:
    case UserType.SourcingManagerManager:
      dashboard = <DashboardSourcingManagerComponent />;
      break;
    case UserType.DriverManager:
      dashboard = <DriverPartnerDashboardComponent />;
      break;
    case UserType.OperationManager:
      dashboard = <OperationManagerDashboardComponent />;
      break;
    default:
      break;
  }
  return dashboard;
}
// function FallbackPageLoader() {
//   return (
//     <div className="page-loading text-center">
//       <div className="content">
//         <img
//           src="dist/img/logo-zenplus.png"
//           className="flipimg"
//           width={"50px"}
//         ></img>{" "}
//         <h6 className="mb-0">Please wait...</h6>
//       </div>
//     </div>
//   );
// }
