import moment from "moment/min/moment-with-locales";

export const DefaultDateFormat = {
  DateTime24Format: "dd/MM/yyyy HH:mm",
  DateTimeFullFormat: "dd/MM/yyyy HH:mm:ss",
  DateTimeFullYMDFormat: "yyyy/MM/dd HH:mm:ss",
  DateTimeFullYMDHHmmFormatSql: "yyyy-MM-dd HH:mm",
  DateFormat: "dd/MM/yyyy",
  DateFormatMonthName: "dd-MMM-yyyy",
  TimeFormat: "HH:mm",
  DayMonthDateFormat: "Do MMM",
  DayMonthYYDateFormat: "Do MMM YY",
  DayMonthYearDateFormat: "Do MMM YYYY",
  DayMonthYYTimeDateFormat: "Do MMM YY h:mm:ss a",
  DayMonthYearTimeDateFormat: "Do MMM YYYY h:mm:ss a",

  MomentDisplayDateTime24Format: "DD/MM/yyyy HH:mm",
  MomentDisplayDateTime24MonthNameFormat: "DD/MMM/yyyy HH:mm",
  MomentDisplayDateTimeAMPMFullFormat: "DD/MM/yyyy hh:mm A",
  MomentDisplayDateTimeFullFormat: "DD/MM/yyyy hh:mm:ss",
  MomentDisplayDateFormat: "DD/MM/yyyy",
  MomentDisplayTimeFormat: "HH:mm",
  MomentDateFormat: "YYYY-MM-DDThh:mm:ss",

  SqlDateTime24Format: "YYYY-MM-DD HH:mm",
  SqlDateTimeFullFormat: "YYYY-MM-DD hh:mm:ss",
  SqlDateFormat: "YYYY-MM-DD",
  SqlTimeFormat: "HH:mm",
  SqlStartDateFormat: "YYYY-MM-DD 00:00:00",
  SqlEndDateFormat: "YYYY-MM-DD 23:59:59",
};
export const DateFilter = {
  CurrentDateTime: new Date(),
  StartDateTime: new Date(moment(new Date()).startOf("day")),
  EndDateTime: new Date(moment(new Date()).endOf("day")),
};
export const CurrencyIcon = {
  ruppesFormat: "fas fa-rupee-sign",
  dollarFormat: "fas fa-dollar-sign",
};
export const PrecisionFormats = {
  0: "0",
  1: "1",
  2: "2",
  3: "3",
};
export const PercentIcon = {
  Normal: "fas fa-percent",
  Xs: "fas fa-percent fa-xs",
  Sm: "fas fa-percent fa-sm",
  Lg: "fas fa-percent fa-lg",
};
export const SchemeAddonType = {
  Incentives: 1001,
  JoiningBonus: 1002,
  ReferalBonus: 1003,
  TripBasedRevenue: 1004,
};
export const UserType = {
  SystemAdministrator: 1,
  Administrator: 2,
  Account: 3,
  Operation: 4,
  Driver: 7,
  TeleAgent: 8,
  Champion: 10,
  FieldExecutive: 12,
  Management: 15,
  Billing: 17,
  DriverRelation: 18,
  Maintainance: 19,
  Leads: 20,
  QCAdmin: 21,
  TrainingAdmin: 22,
  SourcingManagerAdmin: 23,
  SecurityGuardAdmin: 24,
  Referral: 26,
  FreeLancer: 27,
  RepairAndMaintenanceSupervisor: 28,
  DriverManager: 29,
  OperationManager: 30,
  LegalAdmin: 31,
  CollectionManager: 32,
  RepairAndMaintenanceAdmin: 33,
  CityHead: 34,
  RepairAndMaintenanceManager: 35,
  SourcingManager: 36,
  DriverOnboarding: 37,
};
export const LoginAssetType = {
  Web: 1,
};
export const ApplicationType = {
  ApplicationTypeId: 1,
  ApplicationTypeName: "Web Portal",
  ApplicationVersion: "1.0",
};

export const FeatureSourceType = {
  Web: 1,
};
export const MachineName = "Web";

export const RegEx = {
  MobileNumber: /^\d{10}$/,
  Numeric: /^[0-9\b]+$/,
  AlphaNumeric: /^[A-Za-z0-9-]*$/,
  Decimal2Digit: /^\d*(\.\d{0,2})?$/,
  DecimalRulePattern2Digit: /^\\d{0,5}(\\.{0,1})\\d{0,2}$/,
  Mobile: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
  Email:
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PanNumber: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  AadharNumber: /^\d{12}$/,
  Lowercase: /[a-z]/,
  Uppercase: /[A-Z]/,
  Specialcharacters: /[!@#$%^&*]/,
};
// const renumbersymbolexp = /[^0-9]/g;
//                 const lowercaseexp = /[^a-z]/g;
//                 const uppercaseexp = /[^A-Z]/g;
//                 const specialcharacters = /[^!@#$%^&*]/g;

export const SystemStatusType = {
  Customer: 1010,
  Operator: 1011,
  User: 1012,
  Vehicle: 1013,
  Driver: 1014,
  Lead: 1015,
  Uber: 1016,
  Training: 1017,
  InitialPayment: 1018,
  Onboarding: 1019,
  Refund: 1020,
  TrainingResult: 1021,
  VehicleInspection: 1022,
  VehicleAttribute: 1023,
  VehicleOnboarding: 1024,
  VehicleInspectionApproval: 1025,
  StampPaper: 1026,  
  VehicleReturn: 1027,
  VehicleAssignment: 1028,
  SchemeChangeRequest: 1029,
  ReportIncident: 1030,
  Gatepass: 1031,
  DriverParkRequest: 1032, 
  PayLater: 1033,
};

export const AttachmentType = {
  AadharCard: 1,
  PanCard: 2,
  DrivingLicense: 3,
  VoterIdCard: 4,
  DriverProfilePhoto: 5,
  CourtRecordStatus: 6,
  Passbook: 7,
  CancelCheque: 8,
  StatementPage: 9,
  ScannedCheque: 10,
  PoliceClearanceCertificate: 11,
  SecurityGUardMarkIN: 12,
  SecurityGUardMarkOUT: 3,
  RC: 14,
  ChasisNo: 15,
  EngineNo: 16,
  Insurance: 17,
  RoadTax: 18,
  Fitness: 19,
  CarRegistrationDate: 20,
  FastTagNo: 21,
  CarKey: 22,
  SeatCoverFrontLHS: 23,
  SeatCoverFrontRHS: 24,
  SeatCoverBack: 25,
  CarOdometerPhoto: 26,
  CarDashboardPhoto: 27,
  CarFrontPhoto: 28,
  CarRightPhoto: 29,
  CarBackPhoto: 30,
  CarLeftPhoto: 31,
  CarOpenBootPhoto: 32,
  CarEnginePhoto: 33,
  BatteryCompany: 34,
  Tyre1: 35,
  Tyre2: 36,
  Tyre3: 37,
  Tyre4: 38,
  Tyre5: 39,
  FireExtinguisher: 40,
  FloorMat: 48,
  DoorVisor: 49,
  MudFlap: 50,
  ElectricityBill: 51,
  GasBill: 52,
  Passport: 53,
  RentAgreement: 54,
};

export const LeadStatus = {
  INTERESTED: 1,
  NOTINTERESTED: 2,
  FOLLOWUP: 3,
  ACTIVE: 4,
  BLACKLISTED: 5,
  NEWTOUBER: 6,
  INCOMPLETEUBERPROFILE: 7,
  ASKFORDIFFERENTCAR: 8,
  ASKFORDIFFERENTSCHEME: 9,
  DRIVERCUMOWNER: 10,
  TRAININGPENDING: 12,
  TRAININGCOMPLETED: 13,
  NOTPAID: 14,
  REFUNDPENDING: 15,
  REFUNDAPPROVED: 16,
  REFUNDED: 17,
  PARTIALPAID: 18,
  FULLPAID: 19,
  INCOMPLETEPROFILE: 20,
  COMPLETEPROFILE: 21,
  QCPENDING: 22,
  QCAPPROVED: 23,
  QCREJECTED: 24,
  WAITINGVEHICLEALLOCATION: 25,
  AGREEMENTPENDING: 26,
  AGREEMENTCOMPLETED: 27,
  WAITINGHANDOVER: 28,
  HANDOVERCOMPLETED: 29,
  CANCEL: 30,
  NOTAPPLICABLEFORTRAINING: 31,
  NOTAPPLICABLE: 32,
  PASSED: 33,
  FAILED: 34,
  SLOTNOTALLOCATED: 35,
  REFUNDREJECTED: 36,
  PENDING: 37,
  ONBOARDING: 38,
  INSPECTION: 39,
  RETURN: 40,
  OK: 41,
  DAMAGED: 42,
  TORN: 43,
  MISSING: 44,
  REPLACED: 45,
  YES: 46,
  EXPIRED: 47,
  DENTED: 48,
  SCRATCHED: 49,
  PENDINGFORONBOARDING: 50,
  VEHICLEONBOARDINGAPPROVALPENDING: 51,
  VEHICLEONBOARDINGREJECTED: 52,
  INVENTORY: 53,
  INSPECTIONAPPROVED: 54,
  INSPECTIONREJECTED: 55,
  QCSUBMISSIONPENDING: 89,
  RequestPending: 90,
  RequestApproved: 91,
  RequestRejected: 92,
  PayLaterPending: 93,
  PayLaterApproved: 94,
  PayLaterRejected: 95,
  PayLaterReSubmitted: 96,
};

export const VehicleAccessory = {
  CARKEY: 1,
  SEATCOVERFRONTLHS: 2,
  SEATCOVERFRONTRHS: 3,
  SEATCOVERBACK: 4,
  FLOORMAT: 5,
  FULLFLOORMAT: 6,
  STEARINGCOVER: 7,
  CARMOBILECHARGER: 8,
  CARMOBILEHOLDER: 9,
  CARODOMETERPHOTO: 10,
  CARODOMETERREADING: 11,
  CARDASHBOARDPHOTO: 12,
  DOORVISOR: 13,
  CARFRONTPHOTO: 14,
  CARRIGHTPHOTO: 15,
  CARBACKPHOTO: 16,
  CARLEFTPHOTO: 17,
  CAROPENBOOOTPHOTO: 18,
  CARENGINEPHOTO: 19,
  MUDFLAP: 20,
  FIRSTAIDKIT: 21,
  JACK: 22,
  TOOLKIT: 23,
  FIREEXTINGUISHER: 24,
  BATTERYCOMPANY: 25,
  TYRE1: 26,
  TYRE2: 27,
  TYRE3: 28,
  TYRE4: 29,
  TYRE5: 30,
  CAROPENBOOTPHOTO: 31,
  CARHEADLAMPRHS: 32,
  CARHEADLAMPLHS: 33,
  TELLLAMPRHS: 34,
  TELLLAMPLHS: 35,
  OVRMRHS: 36,
  OVRMLHS: 37,
  ENTINA: 38,
  CHASISNO: 39,
  FASTTAG: 40,
  ENGINENo: 41,
};
export const Category = {
  Referral: 1,
  Champion: 2,
  Field: 4,
  TeleSales: 5,
  WalkIn: 6,
  UberCenter: 7,
  Event: 8,
  OLX: 10,
  Apna: 11,
  WorkIndia: 12,
  UberApp: 13,
  Radio: 14,
  NewsPaper: 15,
  CarAdvertising: 16,
  JobHai: 17,
  Indeed: 18,
  Whatsapp: 19,
  Instagram: 20,
  Facebook: 21,
  Youtube: 22,
  Telegram: 23,
  Naukri: 24,
  DriverPark: 25,
  PhysicalFitness: 26,
  InPerson: 27,
};

export const VehicleOnboardingStatus = {
  PendingForOnboarding: 50,
  VehicleOnboardingApprovalPending: 51,
  VehicleOnboardingRejected: 52,
  Inventory: 53,
  VehicleInspectionApproved: 54,
  VehicleInspectionRejected: 55,
  VehicleInspectionStarted: 62,
  PendingforInspection: 63,
  VehicleReturntarted: 64,
  PendingforReturn: 65,
  VehicleReturnApproved: 66,
  VehicleReturnRejected: 67,
};

export const Features = {
  Dashboard: 1,
  Leads: 2,
  DriverPartnerOnboarding: 3,
  Settlement: 4,
  SchemeMaster: 5,
  DocumentQC: 6,
  Refund: 7,
  Vehicles: 8,
  Master: 9,
  Request: 10,
  LeadCost: 11,
  GateRegister: 12,
  DriverStatusChange: 13,
  FieldExecutives: 14,
  Champions: 15,
  Incentives: 16,
  Ticket: 17,
  CarOnboarding: 18,
  CarInventory: 19,
  CarInspection: 20,
  Vendor: 21,
  Training: 22,
  Service: 23,
  StampPaper: 24,
  VehicleProfitLoss: 25,
  User: 26,
  UserRole: 27,
  Feature: 28,
  TrainingSlots: 29,
  TrainingSchedule: 30,
  Ticket: 31,
  Status: 32,
  Category: 33,
  IncentiveScheme: 34,
  Addon: 35,
  Scheme: 36,
  SourcingManagerDashboard: 37,
  PerformanceDashboard: 38,
  AgentCurrentSettlement: 39,
  AgentSettlement: 40,
  Deposit: 41,
  Legal: 42,
  VehicleAllocation: 43,
  LeadAgreement: 44,
  AgreementContract: 45,
  TrainingLocation: 46,
  BranchArea: 47,
  CarImmobilization: 48,
  MarkCarForRecovery: 49,
  Waiver: 50,
  SchemeChangeRequest: 51,
  DriverPark: 52,
  DriverTerminate: 53,
  DriverBlackList: 54,
  Penalty: 55,
  VehicleRelease: 56,
  PaymentCollectionRequest: 57,
  Agreement: 58,
  Deduction: 59,
  Tracking: 60,
  RazorpayReconsillation: 61,
  AgentTargets: 62,
  DriverPartner: 63,
  Operator: 64,
  ServiceCenters: 65,
  DriverPartnerSettlement: 66,
  AgentAttendance: 67,
  ImportData: 68,
  Drivers: 69,
  Vehicles: 70,
  DriverPayout: 71,
  PayoutGenerate: 72,
  PayoutPost: 73,
  Payout: 74,
  SystemPayoutAddon: 75,
  SystemPayoutDeduction: 76,
  SystemPayoutCredit: 77,
  GatePass: 78,
  PayoutProcessedData: 79,
  Branch: 80,
  Agent: 81,
  Partners: 82,
  RM: 83,
  Finance: 84,
  UserManagment: 85,
  QC: 86,
  UserType: 87,
  VehicleChangeRequest: 88,
  VehicleMaster: 89,
  Telecaller: 90,
  PaymentGatewaySetting: 91,
  DriverPerformance: 92,
  DriverStatus: 93,
  ServiceNotification: 94,
  PayLater: 95
};

export const SchemeTypeCode = {
  RS: 1,
  WP: 2,
  HB: 3,
  AP: 4,
  SC: 5,
  FX: 6,
  JB: 7,
  BO: 8,
  QU: 9,
  AB: 10,
};

export const CategoryType = {
  LeadSource: 1,
  LeadChannel: 2,
  SecurityGuard: 3,
  Operator: 4,
  Waiver: 5,
  DriverParkSource: 6,
  TrainingTypes: 7,
  TransactionType: 8,
  IncidentType: 9,
  Penalty: 10,
  Item: 11,
  PaymentType: 12,
  GatepassType: 13,
  Adjustment: 14,
  UserAttendance: 15,
  CallCategory: 16,
};

export const VoiceCallProvider = {
  Exotel: 1,
};
