import React, { Suspense } from "react";
export function Loader(props) {
  var loadingMessage = (props.loadingMessage !== undefined && props.loadingMessage !== "") ? props.loadingMessage : "Please wait...";  
  return (
    <>
      <div className="page-loading text-center">
        <div className="content">
          {/* <img
            src="dist/img/logo-utwiz.png"
            className="flipimg"
            width={"50px"}
          ></img>{" "} */}
           <img
            src="dist/img/logo-zenplus.png"
            className="flipimg"
            width={"50px"}
          ></img>{" "}
          <h6 className="mb-0">{loadingMessage}</h6>
        </div>
      </div>
    </>
  );
}

export function LogoutLoader() {
  return (
    <>
      <div className="page-loading text-center">
        <div className="content">
          {/* <img
            src="dist/img/logo-utwiz.png"
            className="flipimg"
            width={"50px"}
          ></img>{" "} */}
          <img
            src="dist/img/logo-zenplus.png"
            className="flipimg"
            width={"50px"}
          ></img>{" "}          
          <h6 className="mb-0">Logging out...</h6>
        </div>
      </div>
    </>
  );
}
